<template>
  <c-grid
    w="100%"
    :h="{ base: 'calc(130% - 20px)', lg: 'calc(100% - 70px)' }"
    :template-columns="{
      base: '1fr',
      lg: '100% 1fr',
      md: '1fr',
      sm: '1fr',
      xs: '1fr',
    }"
    gap="8"
  >
    <c-stack pb="3" px="2" :spacing="8" maxHeight="100%">
      <NewPosts @postAdded="onPostAdded" />
      <c-text v-if="!isFetchingPosts && !posts.length" fontStyle="italic">
        You have no posts on your timeline
      </c-text>

      <template v-for="post in posts">
        <c-box :key="post.id">
          <PostCard
            @togglePostSave="onPostSaveToggle"
            @selectPostAttachment="
              selectedPost = $event;
              isImagePreviewOpen = true;
            "
            @deletePost="onDeletePost(post)"
            :post="post"
          />
        </c-box>
      </template>
      <infinite-loading @infinite="getMorePosts">
        <span slot="no-more">
          <div style="border: 1px solid rgba(255, 69, 0, 0.45)">
            No more posts
          </div>
        </span>
        <span slot="no-results">
          <div style="border: 1px solid rgba(255, 69, 0, 0.45)">
            No posts found
          </div>
        </span>
      </infinite-loading>
    </c-stack>
  </c-grid>
</template>

<script>
import NewPosts from './components/NewPost.vue';
import PostCard from './components/PostCard.vue';
import { mapState } from 'vuex';
import {
  addPostSave,
  deletePostSave,
  getPosts,
  updatePost,
} from '@/services/insight';
import cloneDeep from 'lodash.clonedeep';
import { generateID } from '@/helpers/data';
import InfiniteLoading from 'vue-infinite-loading';

export default {
  name: 'Index',
  components: {
    NewPosts,
    PostCard,
    InfiniteLoading,
  },

  data() {
    return {
      posts: [],
      savedPosts: [],
      postsOffset: 0,
      postsLimit: 5,
      totalPosts: 0,
      isFetchingPosts: false,
      isFetchingMorePosts: false,
      isDeleteDialogOpen: false,
      isImagePreviewOpen: false,
      postToDelete: null,
      isDeletingPost: false,
      selectedPost: {
        attachments: [],
      },
    };
  },
  computed: {
    ...mapState('auth', {
      user: (state) => state.user,
    }),
  },
  created() {
    this.getPosts();
  },

  methods: {
    onPostAdded(post) {
      this.posts.unshift({ ...post });
    },
    getPosts() {
      this.isFetchingPosts = true;
      getPosts({
        userId: this.user.id,
        limit: this.postsLimit,
        offset: this.postsOffset,
      }).then((res) => {
        this.posts = res.data.post;
        this.savedPosts = res.data.post_save;
        this.totalPosts = res.data.post_aggregate.aggregate.count;
        this.isFetchingPosts = false;
      });
    },
    async onPostSaveToggle(post) {
      const data = {
        postId: post.id,
        userId: this.user.id,
      };
      const postIndex = this.posts.findIndex((_post) => post.id === _post.id);
      if (post.post_saves.length) {
        console.log('Not Added');
        const savedPostIndex = this.savedPosts.findIndex(
          (savedPost) => savedPost.post.id === post.id
        );
        const savedPost = cloneDeep(this.savedPosts[savedPostIndex]);
        this.posts[postIndex].post_saves.pop();
        this.savedPosts.splice(savedPostIndex, 1);
        try {
          await deletePostSave(savedPost.id);
        } catch (e) {
          this.savedPosts.splice(savedPostIndex, 0, { ...savedPost });
          this.posts[postIndex].post_saves.push({ ...data });
          this.$toast({
            title: 'An error occurred.',
            description: `Couldn't remove saved post, please try again.`,
            status: 'error',
            position: 'top',
            duration: 3000,
          });
        }
      } else {
        console.log('Successfully Added');
        this.posts[postIndex].post_saves.push({ ...data });
        const genId = generateID(4);
        this.savedPosts.push({ id: genId, post: { ...post } });
        const savedPostIndex = this.savedPosts.findIndex(
          (savedPost) => savedPost.id === genId
        );
        try {
          const res = await addPostSave(data);
          const { id } = res.data.insert_post_save_one;
          this.savedPosts[savedPostIndex].id = id;
        } catch (e) {
          const savedPostIndex = this.savedPosts.findIndex(
            (savedPost) => savedPost.post.id === post.id
          );
          this.savedPosts.splice(savedPostIndex, 1);
          this.posts[postIndex].post_saves.pop();
          this.$toast({
            title: 'An error occurred.',
            description: `Couldn't save post, please try again.`,
            status: 'error',
            position: 'top',
            duration: 3000,
          });
        }
      }
    },
    // handleScroll(e) {
    //   const element = e.target;
    //   if (
    //     Math.ceil(element.scrollHeight - element.scrollTop) ===
    //     element.clientHeight
    //   ) {
    //     if (
    //       !this.isFetchingMorePosts &&
    //       this.totalPosts !== this.posts.length
    //     ) {
    //       this.getMorePosts();
    //     }
    //   }
    // },
    // handleScroll() {
    //   console.log('Flexing => ')
    //   const { scrollTop, clientHeight, scrollHeight } = this.$refs.scrollableStack;
    //   console.log('Getting here==>',scrollTop, clientHeight, scrollHeight)
    // },

    getMorePosts($state) {
      this.isFetchingMorePosts = true;
      this.postsOffset = this.postsOffset + this.postsLimit;
      getPosts({
        userId: this.user.id,
        limit: this.postsLimit,
        offset: this.postsOffset,
      }).then((res) => {
        this.posts = [...cloneDeep(this.posts), ...res.data.post];
        this.isFetchingMorePosts = false;
        $state.loaded();
        if (this.totalPosts === this.posts.length) {
          $state.complete();
        }
      });
    },
    onDeletePost(post) {
      this.isDeleteDialogOpen = true;
      this.postToDelete = { ...post };
    },
    closeDeleteDialog() {
      this.isDeleteDialogOpen = false;
      this.postToDelete = null;
    },
    async deletePost() {
      this.isDeletingPost = true;
      try {
        await updatePost({
          id: this.postToDelete.id,
          set: { isDeleted: true },
        });
        const postIndex = this.posts.findIndex(
          (post) => post.id === this.postToDelete.id
        );
        this.posts.splice(postIndex, 1);
        this.closeDeleteDialog();
        this.$toast({
          title: 'Success!!!',
          description: `Post has been deleted`,
          status: 'success',
          position: 'top',
          duration: 3000,
        });
      } catch (e) {
        this.$toast({
          title: 'An error occurred.',
          description: `Could not delete post, please try again.`,
          status: 'error',
          position: 'top',
          duration: 3000,
        });
      }
    },
  },
};
</script>

<style scoped></style>
