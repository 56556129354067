<template>
  <div>
    <c-modal
      :is-open="isOpen"
      :on-close="
        () => {
          $emit('close');
        }
      "
    >
      <c-modal-content ref="content">
        <c-modal-header>{{ title }}</c-modal-header>
        <c-modal-close-button />
        <c-modal-body>
          <div v-html="result"></div>
          <!-- {{ context }} -->
        </c-modal-body>
        <c-modal-footer>
          <c-button
            @click="handleGetSuggestion()"
            size="md"
            px="8"
            mt="8"
            mx="2"
            :isLoading="newBtnLoading"
            variant-color="vc-orange"
            variant="outline"
            :disabled="context === ''"
          >
            New
          </c-button>
          <c-button
            @click="handleShort()"
            size="md"
            px="8"
            mt="8"
            mx="2"
            variant-color="vc-orange"
            :isLoading="shortBtnLoading"
            variant="outline"
            :disabled="this.result === ''"
          >
            Short Ans
          </c-button>
          <c-button
            v-if="noBullets"
            @click="handleBullet()"
            size="md"
            px="8"
            mt="8"
            mx="2"
            variant-color="vc-orange"
            :isLoading="bulletBtnLoading"
            variant="outline"
            :disabled="this.result === ''"
          >
            Bullet Points
          </c-button>
        </c-modal-footer>
        <c-button
          @click="useResults()"
          size="md"
          px="2"
          mb="5"
          mt="5"
          mx="5"
          variant-color="vc-orange"
          :disabled="this.result === ''"
        >
          Use This
          <c-text ml="1" fontSize="1.2em" color="#ef5323">👍</c-text>
        </c-button>
      </c-modal-content>
      <c-modal-overlay />
    </c-modal>
  </div>
</template>

<script>
import { getAI } from '../../../services/openAI';

export default {
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
    },
    context: {
      type: String,
    },
    noBullets: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      newBtnLoading: false,
      bulletBtnLoading: false,
      shortBtnLoading: false,
      result: '',
    };
  },
  created() {
    this.result = this.context || '';
  },
  methods: {
    async handleBullet() {
      this.bulletBtnLoading = true;
      try {
        const res = await getAI({
          context: `Get this "${this.result}" in bullet points`,
        });
        this.result = res.data.data;
      } catch (error) {
        console.log(error);
        this.$toast({
          title: 'Error',
          description: `Sorry an error has occured `,
          status: 'success',
          position: 'top',
          duration: 3000,
        });
      }
      this.bulletBtnLoading = false;
    },

    async handleShort() {
      this.shortBtnLoading = true;
      try {
        const res = await getAI({
          context: `Get this "${this.result}" in one sentence`,
        });
        this.result = res.data.data;
      } catch (error) {
        console.log(error);
        this.$toast({
          title: 'AI Points',
          description: `You don't have enough AI points to query results`,
          status: 'success',
          position: 'top',
          duration: 3000,
        });
      }
      this.shortBtnLoading = false;
    },

    async useResults() {
      this.$emit('close', this.result);

      // try {
      //   await navigator.clipboard.writeText(String(this.result));
      //   this.$toast({
      //     title: 'Success.',
      //     description: ` Copied successfully.`,
      //     position: 'top',
      //     status: 'success',
      //     duration: 5000,
      //   });
      // } catch (err) {
      //   console.error('Failed to copy text: ', err);
      // }
    },

    async handleGetSuggestion() {
      if (this.context) {
        this.newBtnLoading = true;

        if (this.title === 'Belief') {
          try {
            const res = await getAI({
              context: `Get a better and improved suggestion of this "${this.context}" as a belief or purpose statement`,
            });
            this.result = res.data.data;
          } catch (error) {
            console.log(error);
            this.$toast({
              title: 'AI Points',
              description: `You don't have enough AI points to query results`,
              status: 'success',
              position: 'top',
              duration: 3000,
            });
          }
          this.newBtnLoading = false;
          return;
        }

        if (this.title === 'Overall Value Proposition') {
          try {
            const res = await getAI({
              context: `Get a better and improved suggestion of this overall value proposition "${this.context}"`,
            });
            this.result = res.data.data;
          } catch (error) {
            console.log(error);
            this.$toast({
              title: 'AI Points',
              description: `You don't have enough AI points to query results`,
              status: 'success',
              position: 'top',
              duration: 3000,
            });
          }
          this.newBtnLoading = false;
          return;
        }

        try {
          const res = await getAI({
            context: `Get a better and improved suggestion of this statement "${this.context}"`,
          });
          this.result = res.data.data;
        } catch (error) {
          console.log(error);
          this.$toast({
            title: 'AI Points',
            description: `You don't have enough AI points to query results`,
            status: 'success',
            position: 'top',
            duration: 3000,
          });
        }
        this.newBtnLoading = false;
      } else {
        this.$toast({
          title: 'Error',
          description: `Sorry, there has to be a context`,
          status: 'error',
          position: 'top',
          duration: 3000,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
button:hover {
  background-color: #e5724e;
  color: #fff;
}
</style>
